import subscription from "../middleware/isSubscribed";

export default [
  {
    path: "/employee/employeeDirectory",
    name: "EmployeeDirectory",
    component: () => import("@/modules/Admin/EmployeeModule/EmployeeDirectory"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/overview",
    name: "EmployeeOverview",
    component: () => import("@/modules/Admin/EmployeeModule/EmployeeOverview"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/confirmation",
    name: "Confirmation",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/confirmation/view-assessment/:id",
    name: "ViewAssesment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/viewAssessment"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/confirmation/create-assessment/:id",
    name: "CreateAssesment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/createAssessment"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/confirmation/confirm-assessment/:id",
    name: "ConfirmAssesment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/viewAssessment"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/confirmation/extend-probation/:id",
    name: "ExtendProbation",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Confirmation/viewAssessment"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/promotion",
    name: "Promotion",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Promotion"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/promotion/new",
    name: "NewPromotion",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Promotion/newpromotion"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/redeployment",
    name: "Redeployment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Redeployment"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/redeployment/new",
    name: "NewRedeployment",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Redeployment/newredeployment"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/delegation-management",
    name: "DelegationManagement",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/DelegationManagement"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeDirectory/create/personal-info",
    name: "CreatePersonalInfo",
    component: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeDirectory/CreateEmployee/personalInfo"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/payroll/EmployeePayroll/EmployeeInformation/:id",
    name: "viewEmployee",
    component: () => import("@/modules/Admin/PayrollModule/viewEmployee"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeDirectory/edit/personal-info/:id",
    name: "EditPersonalInfo",
    props: true,
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeDirectory/EditEmployee/"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeDirectory/edit/employment-details/:id",
    name: "EditEmploymentDetails",
    component: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeDirectory/EditEmployee/employmentDetails"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement",
    name: "EmployeeEngagements",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeEngagement"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement/create/announcement",
    name: "CreateAnnouncement",
    component: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeEngagement/newAnnouncement"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement/create/event",
    name: "CreateEvent",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeEngagement/newEvent"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement/create/group_message",
    name: "CreateGroupMessage",
    component: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeEngagement/newGroupMessage"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement/thread/announcement",
    name: "AnnouncementThread",
    component: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeEngagement/Thread/announcement"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement/thread/direct_message",
    name: "directMessageThread",
    component: () =>
      import(
        "@/modules/Admin/EmployeeModule/EmployeeEngagement/Thread/directMessage"
      ),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement/thread/event",
    name: "eventThread",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeEngagement/Thread/event"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  },
  {
    path: "/employee/employeeEngagement/thread/group",
    name: "groupThread",
    component: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeEngagement/Thread/group"),
    meta: {
      authRequired: true,
      adminRequire: true,
      middleware: [subscription]
    }
  }
];
