/* eslint-disable no-param-reassign */

export default {
  lisOfAllUsers(state) {
    const items = state.lisOfAllUsers;
    items.map(x => {
      x.selected = false;

      return x;
    });
    return state.lisOfAllUsers;
  },
  listAllInvite(state) {
    const items = state.listAllInvite;
    items.map(x => {
      x.selected = false;
      return x;
    });
    return state.listAllInvite;
  },
  allEmployeeDetails(state) {
    return state.allEmployeeDetails;
  },
  employmentDetails({ employmentDetails, employeePersonalInformation }) {
    const {
      employeeId,
      employmentDate,
      employmentType,
      resumptionDate
    } = employmentDetails;
    const {
      userDesignation,
      reportingTo,
      userLevel,
      orgFunction,
      employeeOffice
    } = employeePersonalInformation;
    return {
      employeeId,
      employmentDate,
      employmentType,
      level: userLevel.name,
      function: orgFunction.name,
      designation: userDesignation.name,
      reportingTo,
      resumptionDate,
      officeAddress: employeeOffice.name
    };
  },
  payInformation({ employmentDetails }) {
    const {
      grossPay,
      bank,
      accountNo,
      bvn,
      irs,
      employeeTaxId,
      pencom,
      rsaId,
      hoursPerDay,
      daysPerWeek,
      weeksPerAnnum
    } = employmentDetails;
    return {
      grossPay,
      bank,
      accountNo,
      bvn,
      irs,
      employeeTaxId,
      pencom,
      rsaId,
      hoursPerDay,
      daysPerWeek,
      weeksPerAnnum
    };
  }
};
